import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  date: {
    startDate: "",
    endDate: "",
  },
  approvalDate: {
    approvalStartDate: "",
    approvalEndDate: "",
  },
  invoiceDate: {
    invoiceStartDate: "",
    invoiceEndDate: "",
  },
  sortingData: {
    sortField: "",
    sortOrder: "",
  },
  filterStatus: "",
};

export const FilterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    dateReducer: (state, action) => {
      state.date = { ...state.date, ...action.payload };
    },
    filterStatusReducer: (state, action) => {
      state.filterStatus = action.payload;
    },
    approvalDateReducer: (state, action) => {
      state.approvalDate = { ...state.approvalDate, ...action.payload };
    },
    invoiceDateReducer: (state, action) => {
      state.invoiceDate = { ...state.invoiceDate, ...action.payload };
    },
  },
});

export const {
  dateReducer,
  filterStatusReducer,
  approvalDateReducer,
  invoiceDateReducer,
} = FilterSlice.actions;
export default FilterSlice.reducer;
